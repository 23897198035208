import React, { Component } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import '../scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const TheLayout = React.lazy(() => import('../containers/TheLayout'));

class DashboardRoutes extends Component {
  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>   
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />

              <Redirect to="/" />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default DashboardRoutes;


