import React from "react";
import CryptoJS from "crypto-js";
import moment from "moment";

//Development
// export const URL_CLIENT = "http://localhost:3001";
// const URL_API = "http://localhost:3000";

//Test
 export const URL_CLIENT = "https://agrotrack.agrosoftapp.com";
// const URL_API = "https://api.agrosoftweb.com";

//Production
// export const URL_CLIENT = "https://agrotrack.agrosoftweb.com";
 const URL_API = "https://api.agrosoftweb.com";

const KeyCrypto = "RgUkXp2s5v8y/B?E(H+KbPeShVmYq3t6w9z$C&F)";

export const recordsPerPageSelect = 10;

export const sendPostHttp = ({ parameters, api, route }) => {
  return new Promise((resolve, reject) => {
    const url = `${URL_API}/${api}/${route}`;
    const params = {
      method: "POST",
      body: JSON.stringify(parameters),
      mode: "cors",
      redirect: "follow",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    };

    const request = new Request(url, params);

    fetch(request)
      .then((response) => response.json())
      .then((response) => resolve(response))
      .catch((error) => reject(error.message));
  });
};

export const sendPostHttpWithToken = ({
  parameters,
  api,
  route,
  token_access,
}) => {
  return new Promise((resolve, reject) => {
    const url = `${URL_API}/${api}/${route}`;
    const params = {
      method: "POST",
      body: JSON.stringify(parameters),
      mode: "cors",
      redirect: "follow",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token_access}`,
      }),
    };

    const request = new Request(url, params);

    fetch(request)
      .then((response) => response.json())
      .then((response) => resolve(response))
      .catch((error) => reject(error.message));
  });
};

export const sendPutHttpWithToken = ({
  parameters,
  api,
  route,
  token_access,
}) => {
  return new Promise((resolve, reject) => {
    const url = `${URL_API}/${api}/${route}/${parameters.id}`;
    const params = {
      method: "PUT",
      body: JSON.stringify(parameters),
      mode: "cors",
      redirect: "follow",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token_access}`,
      }),
    };

    const request = new Request(url, params);

    fetch(request)
      .then((response) => response.json())
      .then((response) => resolve(response))
      .catch((error) => reject(error.message));
  });
};

export const sendDeleteHttpWithToken = ({
  parameters,
  api,
  route,
  token_access,
}) => {
  return new Promise((resolve, reject) => {
    const url = `${URL_API}/${api}/${route}/${parameters.id}`;
    const params = {
      method: "DELETE",
      mode: "cors",
      redirect: "follow",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token_access}`,
      }),
    };

    const request = new Request(url, params);

    fetch(request)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          reject(response.message);
        } else {
          resolve(response);
        }
      })
      .catch((error) => reject(error.message));
  });
};

export const sendGetHttp = ({ api, route, token_access }) => {
  return new Promise((resolve, reject) => {
    const url = `${URL_API}/${api}/${route}`;
    const params = {
      method: "GET",
      mode: "cors",
      redirect: "follow",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token_access}`,
      }),
    };

    const request = new Request(url, params);

    fetch(request)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          reject(response.message);
        } else {
          resolve(response);
        }
      })
      .catch((error) => reject(error.message));
  });
};

export const sendGetHttpWithParameters = ({
  api,
  route,
  token_access,
  parameters,
}) => {
  return new Promise((resolve, reject) => {
    const url = new URL(`${URL_API}/${api}/${route}`);
    Object.keys(parameters).forEach((key) =>
      url.searchParams.append(key, parameters[key])
    );
    const params = {
      method: "GET",
      mode: "cors",
      redirect: "follow",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token_access}`,
      }),
    };

    const request = new Request(url, params);

    fetch(request)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          reject(response.message);
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

export const sendGetHttpClean = () => {
  const ids = localStorage.getItem("ids_recordados")
    ? JSON.parse(localStorage.getItem("ids_recordados"))
    : [];
  localStorage.clear();
  localStorage.setItem("ids_recordados", JSON.stringify(ids));
  sessionStorage.clear();
  window.location.reload();
};

export const setLocalStorage = (list) => {
  list.forEach((lt) => {
    if (Array.isArray(lt.value)) {
      localStorage.setItem(lt.name, JSON.stringify(lt.value));
    } else {
      localStorage.setItem(lt.name, lt.value);
    }
  });
};

export const validateNumber = (number_text) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(number_text);
};

export const generateUuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : r && 0x3 | 0x8;
    return v.toString(16);
  });
};

export const validateJson = (value) => {
  try {
    JSON.parse(value);
  } catch (error) {
    return false;
  }

  return true;
};

export const getDateNow = () => {
  let nFecha = new Date();

  let anio = nFecha.getFullYear(),
    mes = nFecha.getMonth() + 1,
    dia = nFecha.getDate();

  if (mes < 10) {
    mes = `0${mes}`;
  }

  if (dia < 10) {
    dia = `0${dia}`;
  }

  return `${anio}-${mes}-${dia}`;
};

export const convertStringToDateHour = (fecha) => {
  return (
    fecha.substr(8, 2) + "/" + fecha.substr(5, 2) + "/" + fecha.substr(0, 4)
  );
};

export const sortBy = (function () {
  var toString = Object.prototype.toString,
    // default parser function
    parse = function (x) {
      return x;
    },
    // gets the item to be sorted
    getItem = function (x) {
      var isObject = x != null && typeof x === "object";
      var isProp = isObject && this.prop in x;
      return this.parser(isProp ? x[this.prop] : x);
    };

  /**
   * Sorts an array of elements.
   *
   * @param {Array} array: the collection to sort
   * @param {Object} cfg: the configuration options
   * @property {String}   cfg.prop: property name (if it is an Array of objects)
   * @property {Boolean}  cfg.desc: determines whether the sort is descending
   * @property {Function} cfg.parser: function to parse the items to expected type
   * @return {Array}
   */
  return function sortby(array, cfg) {
    if (!(array instanceof Array && array.length)) return [];
    if (toString.call(cfg) !== "[object Object]") cfg = {};
    if (typeof cfg.parser !== "function") cfg.parser = parse;
    cfg.desc = !!cfg.desc ? -1 : 1;
    return array.sort(function (a, b) {
      a = getItem.call(cfg, a);
      b = getItem.call(cfg, b);
      return cfg.desc * (a < b ? -1 : +(a > b));
    });
  };
})();

export const getUniqueArray = (array) => {
  return array.filter(function (el, index, arr) {
    return index === arr.indexOf(el);
  });
};

export const tableToExcel = (function () {
  var uri = "data:application/vnd.ms-excel;base64,",
    template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:x="urn:schemas-microsoft-com:office:excel"
        xmlns="http://www.w3.org/TR/REC-html40">
        <meta charset="utf-8"/>
        <head>
            <!--[if gte mso 9]><xml>
                <x:ExcelWorkbook>
                    <x:ExcelWorksheets>
                        <x:ExcelWorksheet>
                            <x:Name>{nombreReporte}</x:Name>
                            <x:WorksheetOptions>
                                <x:DisplayGridlines/>
                            </x:WorksheetOptions>
                        </x:ExcelWorksheet>
                    </x:ExcelWorksheets>
                </x:ExcelWorkbook>
            </xml><![endif]-->
        </head>
            <body>
                <h2 style="font-weight: bold; text-align: center;">{nombreReporte}</h2>
                <table style="border: none; font-size: 13px;">
                    <tr><td>{firstProperty}</td></tr>
                    <tr><td>{secondProperty}</td></tr>
                </table>
                <br/>
                <table style="font-size:13px;">
                    {tabla}
                </table>
            </body>
    </html>`,
    base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format = function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    };
  return function (optionsExcel) {
    //if (!table.nodeType) table = document.getElementById(table)
    //var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
    var ctx = {
      nombreReporte: optionsExcel.nombreReporte,
      tabla: optionsExcel.tabla,
      firstProperty:
        optionsExcel.firstProperty.key +
        ": " +
        optionsExcel.firstProperty.value,
      secondProperty:
        optionsExcel.secondProperty.key !== ""
          ? optionsExcel.secondProperty.key +
            ": " +
            optionsExcel.secondProperty.value
          : "",
    };

    var elementoA = document.createElement("a");
    elementoA.href = uri + base64(format(template, ctx));
    elementoA.download = `${optionsExcel.nombreReporte}.xls`;
    elementoA.target = "_blank";

    elementoA.click();
  };
})();

export const getFechaActualLetras = () => {
  var fecha = new Date();

  var diaNumero = parseInt(fecha.getDay());
  var dia = null;
  var diaDelMes = fecha.getDate();

  var mesNumero = parseInt(fecha.getMonth());
  var mes = null;

  var anio = fecha.getFullYear();

  switch (diaNumero) {
    case 0:
      dia = "Domingo";
      break;
    case 1:
      dia = "Lunes";
      break;
    case 2:
      dia = "Martes";
      break;
    case 3:
      dia = "Miércoles";
      break;
    case 4:
      dia = "Jueves";
      break;
    case 5:
      dia = "Viernes";
      break;
    case 6:
      dia = "Sábado";
      break;

    default:
      break;
  }

  switch (mesNumero) {
    case 0:
      mes = "Enero";
      break;
    case 1:
      mes = "Febrero";
      break;
    case 2:
      mes = "Marzo";
      break;
    case 3:
      mes = "Abril";
      break;
    case 4:
      mes = "Mayo";
      break;
    case 5:
      mes = "Junio";
      break;
    case 6:
      mes = "Julio";
      break;
    case 7:
      mes = "Agosto";
      break;
    case 8:
      mes = "Setiembre";
      break;
    case 9:
      mes = "Octubre";
      break;
    case 10:
      mes = "Noviembre";
      break;
    case 11:
      mes = "Diciembre";
      break;

    default:
      break;
  }

  return dia + ", " + diaDelMes + " de " + mes + " de " + anio;
};

export const EncryptText = (Val) => {
  try {
    return CryptoJS.AES.encrypt(Val, KeyCrypto).toString();
  } catch (error) {
    throw error;
  }
};

export const DecryptText = (Val) => {
  try {
    return CryptoJS.AES.decrypt(Val, KeyCrypto).toString(CryptoJS.enc.Utf8);
  } catch (error) {
    throw error;
  }
};

export const randomStringGenerate = () => {
  let chars = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
  let string_length = 8;
  let randomstring = "";
  let charCount = 0;
  let numCount = 0;

  for (var i = 0; i < string_length; i++) {
    if (
      (Math.floor(Math.random() * 2) === 0 && numCount < 3) ||
      charCount >= 5
    ) {
      let rnum = Math.floor(Math.random() * 10);
      randomstring += rnum;
      numCount += 1;
    } else {
      let rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
      charCount += 1;
    }
  }

  if (randomstring === randomstring.toUpperCase()) {
    return randomStringGenerate();
  } else if (randomstring === randomstring.toLowerCase()) {
    return randomStringGenerate();
  }

  return randomstring;
};

export const validatePassword = (passwordText) => {
  let regexValidate = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  return regexValidate.test(passwordText);
};

export const setSelectRecorsDataTable = (itemsPerPage) => {
  // eslint-disable-next-line no-undef
  const selectDOM = $("select");

  // eslint-disable-next-line no-undef
  const label = $('label[class="mr-2"]');

  // eslint-disable-next-line no-undef
  const filter = $(label)[0];
  // eslint-disable-next-line no-undef
  $(filter).parent().parent().addClass("col-md-12");

  if (selectDOM) {
    const keysSelect = Object.keys(selectDOM);
    const positions = keysSelect.filter(
      (k) => k !== "length" && k !== "prevObject"
    );
    // eslint-disable-next-line no-undef
    const itemsPerPageSelect = $(selectDOM)[positions.length - 1];
    // eslint-disable-next-line no-undef
    $(itemsPerPageSelect).val(!itemsPerPage ? "10" : itemsPerPage.toString());
  }
};

export const validarFecha = (fecha) => {
  var regex = new RegExp(
    "([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})"
  );
  let result = regex.test(fecha);
  return result;
};

export const formatTime = (fecha) => {
  let hours = fecha.substr(11, 2);
  let minutes = fecha.substr(14, 2);
  let seconds = fecha.substr(17, 2);
  var isAm = true;
  if (hours > 12) {
    isAm = false;
    hours -= 12;
  } else if (hours === 12) {
    isAm = false;
  }
  let time = `${hours}:${minutes}:${seconds} ` + (isAm ? "a.m." : "p.m.");
  return time;
};

export const formatDates = (fecha_inicio, hora_inicio) => {
  let fechaInicio = fecha_inicio;
  let fechaFin = fechaInicio + " " + "23" + ":" + "59" + ":" + "00";

  let horaInicio = hora_inicio;

  fechaInicio = (fechaInicio + " " + horaInicio).toString().trim();

  return {
    fechaInicio,
    fechaFin,
  };
};

export const getDateTimeActual = () => {
  const dateNow = new Date();
  dateNow.setMinutes(dateNow.getMinutes() - dateNow.getTimezoneOffset());

  const fecha = dateNow.toISOString().slice(0, 10);
  const hora_init = "T00:00";
  const hora_fin = "T23:59";

  const dateInit = `${fecha}${hora_init}`;
  const dateFinish = `${fecha}${hora_fin}`;

  return { dateInit, dateFinish };
};

export const getDateTimeRecorrido = () => {
  let dateFinish = moment();
  let dateInit = dateFinish.clone();
  dateInit = dateInit.subtract(15, "minutes");

  dateFinish = dateFinish.format("YYYY-MM-DDTHH:mm");
  dateInit = dateInit.format("YYYY-MM-DDTHH:mm");

  return { dateInit, dateFinish };
};

export const organizarFechaHora = (fechaHora = "") => {
  const fecha = fechaHora.substr(0, 10);
  const hora = fechaHora.substr(11, 5);

  return `${fecha} ${hora}:00`;
};

export const verifiedAccessAdmin = (history) => {
  const isAdmin = DecryptText(localStorage.getItem("perfil"));
  if (parseInt(isAdmin) !== 1) {
    history.push("/dashboard");
    return;
  }
};

export const validarExternos = (id) => {
  let empresa = null;
  if (id === "627426817" || id === "111111111") {
    empresa = "agricola chapi";
  } else if (id === "698417438") {
    empresa = "cao";
  } else if (id === "654852142") {
    empresa = "agrovision";
  } else if (id === "811475053") {
    empresa = "huarmey ica";
  } else if (id === "584268418") {
    empresa = "viru";
  } else if (id === "498216872") {
    empresa = "proserla";
  } else if (id === "613872463") {
    empresa = "citrusco";
  }
  return empresa;
  // return (id === '627426817' || id == '111111111') ? 'agricola chapi' : id === '698417438' ? "cao" : id === '654852142' ? 'agrovision' : null;
};

export const tableToExcelGeneral = (function () {
  var uri = "data:application/vnd.ms-excel;base64,",
    template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:x="urn:schemas-microsoft-com:office:excel"
        xmlns="http://www.w3.org/TR/REC-html40">
        <meta charset="utf-8"/>
        <head>
            <!--[if gte mso 9]><xml>
                <x:ExcelWorkbook>
                    <x:ExcelWorksheets>
                        <x:ExcelWorksheet>
                            <x:Name>{nombreReporte}</x:Name>
                            <x:WorksheetOptions>
                                <x:DisplayGridlines/>
                            </x:WorksheetOptions>
                        </x:ExcelWorksheet>
                    </x:ExcelWorksheets>
                </x:ExcelWorkbook>
            </xml><![endif]-->
        </head>
            <body>
                <h2 style="font-weight: bold; text-align: center;">{nombreReporte}</h2>
                <table style="border: none; font-size: 13px;">
                    <tr><td>{fundo}</td></tr>
					<tr><td>{fechaInicio}</td></tr>
					<tr><td>{fechaFin}</td></tr>
                </table>
                <br/>
                <table style="font-size:13px;">
                    {tabla}
                </table>
            </body>
    </html>`,
    base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format = function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    };
  return function (optionsExcel) {
    //if (!table.nodeType) table = document.getElementById(table)
    //var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
    var ctx = {
      nombreReporte: optionsExcel.nombreReporte,
      tabla: optionsExcel.tabla,
      fundo: "Fundo: " + optionsExcel.fundo,
      fechaInicio: "Fecha Inicio: " + optionsExcel.fechaInicio,
      fechaFin: "Fecha Fin: " + optionsExcel.fechaFin,
    };

    var elementoA = document.createElement("a");
    elementoA.href = uri + base64(format(template, ctx));
    elementoA.download = `${optionsExcel.nombreReporte}.xls`;
    elementoA.target = "_blank";

    elementoA.click();
  };
})();

export const ConvertStringToDateHourLarge = (fecha) => {
  return (
    fecha.substr(8, 2) +
    "/" +
    fecha.substr(5, 2) +
    "/" +
    fecha.substr(0, 4) +
    " " +
    fecha.substr(11, 2) +
    ":" +
    fecha.substr(14, 2)
  );
};

//#Region variables reutilizables

export const maquinas = localStorage.getItem("maquinas_fundo")
  ? JSON.parse(localStorage.getItem("maquinas_fundo"))
  : [];

export const tiposMaquina = localStorage.getItem("tipo_maquinas")
  ? JSON.parse(localStorage.getItem("tipo_maquinas"))
  : [];

const shapes = localStorage.getItem("shapes")
  ? JSON.parse(localStorage.getItem("shapes"))
  : [];

const getRangoVelocidades = () => {
  if (
    localStorage.getItem("rango_velocidades") &&
    localStorage.getItem("rango_velocidades") !== "undefined"
  ) {
    return JSON.parse(localStorage.getItem("rango_velocidades"));
  } else if (
    localStorage.getItem("rango_velocidad") &&
    localStorage.getItem("rango_velocidad") !== "undefined"
  ) {
    return JSON.parse(localStorage.getItem("rango_velocidad"));
  }
  return [];
};
export const rango_velocidades = getRangoVelocidades();

export const fundos = shapes
  .filter((shape) => shape.cod_tipo_shape === 1)
  .sort(function (a, b) {
    return a.etiqueta_shape === b.etiqueta_shape
      ? 0
      : a.etiqueta_shape > b.etiqueta_shape
      ? 1
      : -1;
  });
//#endregion

//#Region funciones reutilizables
export const renderOptions = (data, propertyKey, propertyValue) => {
  return data.map((element, index) => {
    return (
      <option key={index} value={element[propertyKey]}>
        {element[propertyValue]}
      </option>
    );
  });
};

export const validationDifferenceDates = (
  firstDate,
  secondDate,
  daysDifference
) => {
  let firstDateAsMoment = moment(firstDate);
  let SecondDateAsMoment = moment(secondDate);
  const diferencia = SecondDateAsMoment.diff(firstDateAsMoment, "days");

  let validate = { error: false };
  if (diferencia > daysDifference) {
    validate = {
      error: true,
      msg: `La diferencia entre las fechas es máximo de ${daysDifference} días`,
    };
  } else if (diferencia < 0) {
    validate = {
      error: true,
      msg: `La fecha inicio no puede ser posterior a la fecha fin`,
    };
  }
  return validate;
};

export const validationDifferenceHours = (
  firstDate,
  secondDate,
  daysDifference
) => {
  let firstDateAsMoment = moment(firstDate);
  let SecondDateAsMoment = moment(secondDate);
  const diferencia = SecondDateAsMoment.diff(firstDateAsMoment, "hours");
  let validate = { error: false };
  if (diferencia > daysDifference) {
    validate = {
      error: true,
      msg: `La diferencia entre las fechas es máximo de ${daysDifference} horas`,
    };
  } else if (diferencia < 0) {
    validate = {
      error: true,
      msg: `La fecha inicio no puede ser posterior a la fecha fin`,
    };
  }
  return validate;
};

//#endregion
