import {
  DecryptText,
  validarExternos,
  EncryptText,
  sendGetHttp,
  sendGetHttpClean,
  sendGetHttpWithParameters,
  sendPostHttp,
  setLocalStorage,
  validateJson,
} from "../utils/helpers";

export function AuthLogin({ parameters }, remember) {
  return new Promise(async (resolve, reject) => {
    const API = "configuracion";
    const ROUTE = "login";

    try {
      const response = await sendPostHttp({
        parameters,
        api: API,
        route: ROUTE,
      });

      if (response.error) {
        return reject(response.message);
      }

      const externos = validarExternos(response.id_aplicacion.toString());
      const nomb_empresa = !externos ? response.empresa : externos;

      const responseCartografias = await sendGetHttpWithParameters({
        api: "agrotrack/api_reportes",
        route: "cartografias",
        token_access: response.token,
        parameters: { nomb_empresa },
      });

      const coordenadasCentro = {
        latitud: responseCartografias.coordenadas.Latitud,
        longitud: responseCartografias.coordenadas.Longitud,
        zoom: responseCartografias.coordenadas.Zoom,
      };

      const token_encrypted = EncryptText(response.token);
      const empresa_encrypted = EncryptText(response.empresa);
      const usuario_encrypted = EncryptText(response.usuario);
      const id_aplicacion_encrypted = EncryptText(
        response.id_aplicacion.toString()
      );
      const perfil_encrypted = EncryptText(
        response.dataUsuarioEmpresa.id_perfil.toString()
      );
      response.InformacionCargaInicial.Maquinas.sort((a, b) => {
        if (a.desc_maquina > b.desc_maquina) {
          return 1;
        }
        if (a.desc_maquina < b.desc_maquina) {
          return -1;
        }
        return 0;
      });
      response.InformacionCargaInicial.Maquinas.unshift({ cod_maquina: "-1" });

      if (remember) {
        const ids = localStorage.getItem("ids_recordados")
          ? JSON.parse(localStorage.getItem("ids_recordados"))
          : [];
        const isCreated = ids.find((x) => x.id === response.id_aplicacion);
        if (!isCreated) {
          localStorage.setItem(
            "ids_recordados",
            JSON.stringify([
              ...ids,
              { id: response.id_aplicacion, empresa: response.empresa },
            ])
          );
        }
      }

      setLocalStorage([
        { name: "known_signed", value: token_encrypted },
        { name: "app", value: EncryptText("agrotrackv13") },
        { name: "id_aplicacion", value: id_aplicacion_encrypted },
        { name: "usuario", value: usuario_encrypted },
        { name: "empresa", value: empresa_encrypted },
        { name: "perfil", value: perfil_encrypted },
        { name: "cartografias", value: responseCartografias.data },
        {
          name: "latitud_inicial",
          value: coordenadasCentro.latitud.toString(),
        },
        {
          name: "longitud_inicial",
          value: coordenadasCentro.longitud.toString(),
        },
        { name: "zoom_inicial", value: coordenadasCentro.zoom.toString() },
      ]);

      setLocalStorage([
        { name: "maquinas", value: response.InformacionCargaInicial.Maquinas },
        { name: "labores", value: response.InformacionCargaInicial.Labores },
        {
          name: "objetivos",
          value: response.InformacionCargaInicial.Objetivos,
        },
        {
          name: "implementos",
          value: response.InformacionCargaInicial.Implementos,
        },
        {
          name: "responsables",
          value: response.InformacionCargaInicial.Responsables,
        },
        {
          name: "operadores",
          value: response.InformacionCargaInicial.Operadores,
        },
        {
          name: "tipo_shapes",
          value: response.InformacionCargaInicial.TipoShapes,
        },
        { name: "shapes", value: response.InformacionCargaInicial.Shapes },
        {
          name: "tipo_maquinas",
          value: response.InformacionCargaInicial.TipoMaquinas,
        },
        {
          name: "maquinas_fundo",
          value: response.InformacionCargaInicial.MaquinasFundo,
        },
        {
          name: "rango_velocidad",
          value: response.InformacionCargaInicial.RangoVelocidad,
        },
        { name: "cultivos", value: response.InformacionCargaInicial.Cultivos },
        { name: "modulos", value: response.InformacionCargaInicial.Modulos },
        { name: "turnos", value: response.InformacionCargaInicial.Turnos },
        {
          name: "productos",
          value: response.InformacionCargaInicial.Productos,
        },
        {
          name: "tipo_registro",
          value: response.InformacionCargaInicial.TipoRegistro,
        },
        {
          name: "rango_velocidades",
          value: response.InformacionCargaInicial.RangoVelocidades,
        },
      ]);

      resolve(true);
    } catch (error) {
      const message_error_server_500 =
        error === "Failed to fetch"
          ? "Hay problemas con la conexión. Intentelo más tarde."
          : error;
      reject(message_error_server_500);
    }
  });
}

export function verifyJWTApi() {
  return new Promise(async (resolve, reject) => {
    const API = "configuracion";
    const ROUTE = "jwtVerifiyClients";
    const TokenLocalStorage = localStorage.getItem("known_signed");

    if (localStorage.length === 0) {
      return reject(false);
    }

    if (!TokenLocalStorage) {
      return reject(false);
    }

    const TOKEN_ACCESS = DecryptText(localStorage.getItem("known_signed"));

    try {
      const response = await sendGetHttp({
        api: API,
        route: ROUTE,
        token_access: TOKEN_ACCESS,
      });

      if (response.error) {
        return reject(response.message);
      }

      resolve("");
    } catch (error) {
      const ids = localStorage.getItem("ids_recordados")
        ? JSON.parse(localStorage.getItem("ids_recordados"))
        : [];
      localStorage.clear();
      localStorage.setItem("ids_recordados", JSON.stringify(ids));
      window.location.reload();
      const message_error_server_500 =
        error === "Failed to fetch"
          ? "Hay problemas con la conexión. Intentelo más tarde."
          : error;
      reject(message_error_server_500);
    }
  });
}

export function validateKeysLocalStorage() {
  if (!validateJson(localStorage.getItem("maquinas"))) {
    return false;
  }

  const storeInitial = [
    localStorage.getItem("empresa"),
    localStorage.getItem("id_aplicacion"),
    localStorage.getItem("known_signed"),
    localStorage.getItem("usuario"),
  ];

  let confirmation = true;

  for (let i = 0; i < storeInitial.length; i++) {
    if (!storeInitial[i]) {
      confirmation = null;
      break;
    }
  }

  if (!confirmation) {
    return false;
  } else {
    return true;
  }
}

export function validateInitialKeysLocalStorage() {
  const app = localStorage.getItem("app")
    ? DecryptText(localStorage.getItem("app"))
    : "";

  if (app !== "agrotrackv13") {
    const ids = localStorage.getItem("ids_recordados")
      ? JSON.parse(localStorage.getItem("ids_recordados"))
      : [];
    localStorage.clear();
    localStorage.setItem("ids_recordados", JSON.stringify(ids));
  }
}

export function AuthLogout() {
  sendGetHttpClean();
}
