import { types } from "../../types/types";

const initial_state = {
    mode_update: null,
    mode_insert: true
};

export const mantenimientoReducer = (state = initial_state, action) => {
    switch (action.type) {
        //#region Evaluadores
        case types.mantenimiento_Evaluadores_ADD:
            return {
                ...state,
                evaluadores: [action.payload, ...state.evaluadores]
            };

        case types.mantenimiento_Evaluadores_UPDATE:
            return {
                ...state,
                evaluadores: state.evaluadores.map(evaluador => {
                    return evaluador.id_evaluador === action.payload.id_evaluador
                        ? action.payload
                        : evaluador
                })
            };

        case types.mantenimiento_Evaluadores_DELETE:
            return {
                ...state,
                evaluadores: state.evaluadores.filter(evaluador => evaluador.id_evaluador !== action.payload.id_evaluador)
            };
        //#endregion

        //#region Usuarios
        case types.mantenimiento_Usuarios_ADD:
            return {
                ...state,
                usuarios: [action.payload, ...state.usuarios]
            };

        case types.mantenimiento_Usuarios_UPDATE:
            return {
                ...state,
                usuarios: state.usuarios.map(usuario => {
                    return usuario.id_usuario === action.payload.id_usuario
                        ? action.payload
                        : usuario
                })
            };

        case types.mantenimiento_Usuarios_DELETE:
            return {
                ...state,
                usuarios: state.usuarios.filter(usuario => usuario.id_usuario !== action.payload.id_usuario)
            };
        //#endregion


        case types.mantenimiento_InsertStatus_On:
            return {
                ...state,
                mode_insert: true,
                mode_update: false
            };

        case types.mantenimiento_InsertStatus_Off:
            return {
                ...state,
                mode_insert: false,
                mode_update: false
            };

        case types.mantenimiento_UpdateStatus_On:
            return {
                ...state,
                mode_insert: false,
                mode_update: true
            };

        case types.mantenimiento_UpdateStatus_Off:
            return {
                ...state,
                mode_insert: false,
                mode_update: false
            };
        default:
            return state;
    }
}