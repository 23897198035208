import { types } from "../../types/types";
import { DecryptText, generateUuidv4 } from "../../utils/helpers";

const initialState = {
    mapacargado: false,
    vistaLote: false,
    gpscargado: false
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.auth_Login:
            return {
                ...state,
                uid: generateUuidv4(),
                id: action.payload.id_aplicacion,
                name: action.payload.username
            };

        case types.auth_Finish:
            return {
                ...state,
                init: true
            };

        case types.auth_load_data: {
            return {
                ...state,
                load_data: true,
                fundos: action.payload.fundos,
                cultivos: action.payload.cultivos,
                campanas: action.payload.campanas
            };
        }

        case types.auth_load_dataCombos: {
            return {
                ...state,
                lotes: JSON.parse(localStorage.getItem('lotes')).filter(lote => {
                    return lote.cod_campana === DecryptText(localStorage.getItem('cod_campana')) || lote.cod_lote === '-1'
                }),
                niveles: JSON.parse(localStorage.getItem('niveles')).filter(nivel => {
                    return nivel.cod_cultivo === DecryptText(localStorage.getItem('cod_cultivo'))
                }),
                cartillas: JSON.parse(localStorage.getItem('cartillas')).filter(cartilla => {
                    return cartilla.cod_cultivo === DecryptText(localStorage.getItem('cod_cultivo')) || cartilla.id_cartilla === '-1'
                }),
                elementos: JSON.parse(localStorage.getItem('elementos')).filter(elemento => {
                    return elemento.activo === true
                }),
                evaluadores: JSON.parse(localStorage.getItem('evaluadores')).filter(elemento => {
                    return elemento.activo === true || elemento.id_evaluador === '-1'
                })
            };
        }

        case types.mapasLotesCargado: {
            return {
                ...state,
                mapacargado: !state.mapacargado
            }
        }

        case types.mapasGpsCargado: {
            return {
                ...state,
                gpscargado: !state.gpscargado
            }
        }

        case types.mapasVistaLotesLabels: {
            return {
                ...state,
                vistaLote: action.payload
            }
        }

        case types.auth_Logout:
            return { };

        default:
            return state;
    }
}