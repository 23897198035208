import { types } from "../../types/types";

const initial_state = {
    loading: false,
    messageError: null
};

export const uiReducer = (state = initial_state, action) => {
    switch (action.type) {
        case types.ui_StartLoading:
            return { ...state, loading: true };

        case types.ui_FinishLoading:
            return { ...state, loading: false };

        case types.ui_SetError:
            return { ...state, messageError: action.payload.message };

        case types.ui_RemoveError:
            return { ...state, messageError: null };

        default:
            return state;
    }
}