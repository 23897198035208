import { types } from "../../types/types";

const initial_state = {
    orden_actual: null
};

export const ordenReducer = (state = initial_state, action) => {
    switch (action.type) {
        case types.setOrdenActual:
            return { 
              orden_actual: action.payload
            };
        case types.removeOrdenActual: 
            return {
              orden_actual: null,
            }
        default:
            return state;
    }
}
